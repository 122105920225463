import request from '@/utils/request'


// 查询广告投放列表
export function listAdv(query) {
    return request({
        url: '/platform/adv/list',
        method: 'get',
        params: query
    })
}

// 查询广告投放分页
export function pageAdv(query) {
    return request({
        url: '/platform/adv/page',
        method: 'get',
        params: query
    })
}

// 查询广告投放详细
export function getAdv(data) {
    return request({
        url: '/platform/adv/detail',
        method: 'get',
        params: data
    })
}

// 新增广告投放
export function addAdv(data) {
    return request({
        url: '/platform/adv/add',
        method: 'post',
        data: data
    })
}

// 修改广告投放
export function updateAdv(data) {
    return request({
        url: '/platform/adv/edit',
        method: 'post',
        data: data
    })
}

// 删除广告投放
export function delAdv(data) {
    return request({
        url: '/platform/adv/delete',
        method: 'post',
        data: data
    })
}

export function changeStatus(id) {
    return request({
        url: '/platform/adv/changeStatus?id=' + id,
        method: 'post'
    })
}